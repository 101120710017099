import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import logo from "../../assets/logo.png"; // Ensure the logo path is correct
import { FiMenu, FiX } from "react-icons/fi"; // React Icons for menu toggle
import { IconContext } from "react-icons"; // To set global icon properties
import { TbLogs } from "react-icons/tb";
import { HiOutlineUserGroup } from "react-icons/hi2";
// Font Awesome Icons
import { FaDollarSign, FaMoneyBillWave, FaRegAddressCard, FaCookieBite } from 'react-icons/fa';

// Material Design Icons
import { MdGroups, MdSettings } from 'react-icons/md';

// Ant Design Icons
import { AiOutlineTeam, AiOutlineFileText } from 'react-icons/ai';

// Remix Icons
import { RiArticleLine } from 'react-icons/ri';

import { GrTechnology } from "react-icons/gr";
import { IoPricetag } from "react-icons/io5";

import { FaHandshakeSimple } from "react-icons/fa6";

import { LuCopyCheck } from "react-icons/lu";

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [megaMenuOpen, setMegaMenuOpen] = useState(false);
  const [mobileMegaMenuOpen, setMobileMegaMenuOpen] = useState(false); // Separate state for mobile mega menu
  const navigate = useNavigate();
  const closeMenuTimeout = useRef(null);

  const handleMouseEnter = () => {
    if (closeMenuTimeout.current) {
      clearTimeout(closeMenuTimeout.current);
      closeMenuTimeout.current = null;
    }
    setMegaMenuOpen(true);
  };

  const handleMouseLeave = () => {
    closeMenuTimeout.current = setTimeout(() => {
      setMegaMenuOpen(false);
    }, 400); // Delay in milliseconds
  };

  useEffect(() => {
    // Cleanup timeout on unmount
    return () => {
      if (closeMenuTimeout.current) {
        clearTimeout(closeMenuTimeout.current);
      }
    };
  }, []);

  // Function to close mobile menu when navigating
  const handleNavigate = (path) => {
    navigate(path);
    setMobileMenuOpen(false);
    setMobileMegaMenuOpen(false);
  };

  return (
    <header className="backdrop-blur-md antialiased">
      <div className="max-w-screen-xl mx-auto px-4 py-4 flex items-center justify-between md:px-8 lg:px-6">
        {/* Logo Section */}
        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img src={logo} alt="Asendia AI Logo" className="h-12 object-contain -mr-4" />
          <span className="ml-3 text-xl font-semibold text-gray-900 mr-4">
            Asendia AI
          </span>
        </div>

        {/* Desktop Navigation Links */}
        <nav className="hidden md:flex space-x-6 text-sm font-medium">
          <span
            className="text-gray-700 hover:text-indigo-600 cursor-pointer"
            onClick={() => navigate("/companies")}
          >
            For Recruiters
          </span>
          <span
            className="text-gray-700 hover:text-indigo-600 cursor-pointer"
            onClick={() => navigate("/job-seekers")}
          >
            For Jobseekers
          </span>
          {/* Resources + Mega Menu */}
          <div
            className="relative"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <span className="text-gray-700 hover:text-indigo-600 cursor-pointer flex items-center">
              Resources
              <svg
                className="w-4 h-4 ml-1"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
              >
                <path d="M19 9l-7 7-7-7" />
              </svg>
            </span>

            {/* Mega Menu Dropdown */}
            {megaMenuOpen && (
              <div
                className="
                  absolute 
                  left-1/2 
                  top-full 
                  transform 
                  -translate-x-1/2 
                  mt-7
                  w-[90vw] 
                  max-w-6xl 
                  mx-auto 
                  bg-white 
                  shadow-lg 
                  rounded-bl-3xl
                  rounded-br-3xl
                  p-6 
                  z-20
                "
              >
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                  {/* For Recruiters Column */}
                  <div>
                    <h6 className="font-medium text-sm text-gray-500 mb-2">
                      For Recruiters
                    </h6>
                    <ul className="space-y-4">
                      <li>
                        <a
                          href={`${window.location.origin}/features`}
                          className="flex items-start hover:bg-gray-100 p-3 rounded-lg transition"
                        >
                          <div className="bg-indigo-50 rounded-lg p-2 flex items-center justify-center">
                            <GrTechnology className="text-indigo-500 w-6 h-6" />
                          </div>
                          <div className="ml-4">
                            <h5 className="text-gray-900 text-base mb-1.5 font-semibold">
                              Features
                            </h5>
                            <p className="text-xs font-medium text-gray-400">
                              Uncover powerful tools designed to streamline your hiring process.
                            </p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          href={`${window.location.origin}/pricing`}
                          className="flex items-start hover:bg-gray-100 p-3 rounded-lg transition"
                        >
                          <div className="bg-indigo-50 rounded-lg p-2 flex items-center justify-center">
                            <IoPricetag className="text-indigo-500 w-6 h-6" />
                          </div>
                          <div className="ml-4">
                            <h5 className="text-gray-900 text-base mb-1.5 font-semibold">
                              Pricing{" "}
                              <span className="bg-indigo-50 text-indigo-500 text-xs font-medium mr-2 px-2.5 py-1 rounded-full">
                                New
                              </span>
                            </h5>
                            <p className="text-xs font-medium text-gray-400">
                              Find cost-effective plans to meet your recruitment needs.
                            </p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          href={`${window.location.origin}/partners`}
                          className="flex items-start hover:bg-gray-100 p-3 rounded-lg transition"
                        >
                          <div className="bg-indigo-50 rounded-lg p-2 flex items-center justify-center">
                            <FaHandshakeSimple className="text-indigo-500 w-6 h-6" />
                          </div>
                          <div className="ml-4">
                            <h5 className="text-gray-900 text-base mb-1.5 font-semibold">
                              Our Partners{" "}
                              <span className="bg-indigo-50 text-indigo-500 text-xs font-medium mr-2 px-2.5 py-1 rounded-full">
                                New
                              </span>
                            </h5>
                            <p className="text-xs font-medium text-gray-400">
                              Learn about the trusted partners contributing to recruitment success.
                            </p>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>

                  {/* For Jobseekers Column */}
                  <div>
                    <h6 className="font-medium text-sm text-gray-500 mb-2">
                      For Jobseekers
                    </h6>
                    <ul className="space-y-4">
                      <li>
                        <a
                          href={`${window.location.origin}/job-seekers/pricing`}
                          
                          className="flex items-start hover:bg-gray-100 p-3 rounded-lg transition"
                        >
                          <div className="bg-indigo-50 rounded-lg p-2 flex items-center justify-center">
                            <IoPricetag className="text-indigo-500 w-6 h-6" />
                          </div>
                          <div className="ml-4">
                            <h5 className="text-gray-900 text-base mb-1.5 font-semibold">
                              Pricing
                            </h5>
                            <p className="text-xs font-medium text-gray-400">
                              Explore affordable plans tailored for job seekers.
                            </p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          href={`${window.location.origin}/job-seekers/partners`}
                          className="flex items-start hover:bg-gray-100 p-3 rounded-lg transition"
                        >
                          <div className="bg-indigo-50 rounded-lg p-2 flex items-center justify-center">
                            <HiOutlineUserGroup  className="text-indigo-500 w-6 h-6" />
                          </div>
                          <div className="ml-4">
                            <h5 className="text-gray-900 text-base mb-1.5 font-semibold">
                              Community Partners
                            </h5>
                            <p className="text-xs font-medium text-gray-400">
                              Discover the organizations collaborating to support your career goals.
                            </p>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>

                  {/* General Column */}
                  <div>
                    <h6 className="font-medium text-sm text-gray-500 mb-2">
                      General
                    </h6>
                    <ul className="space-y-4">
                      <li>
                        <a
                          href={`${window.location.origin}/aboutus`}
                          className="flex items-start hover:bg-gray-100 p-3 rounded-lg transition"
                        >
                          <div className="bg-indigo-50 rounded-lg p-2 flex items-center justify-center">
                            <RiArticleLine className="text-indigo-500 w-6 h-6" />
                          </div>
                          <div className="ml-4">
                            <h5 className="text-gray-900 text-base mb-1.5 font-semibold">
                              About Us
                            </h5>
                            <p className="text-xs font-medium text-gray-400">
                              Get to know our mission, values, and story.
                            </p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          href={`${window.location.origin}/blogs`}
                          className="flex items-start hover:bg-gray-100 p-3 rounded-lg transition"
                        >
                          <div className="bg-indigo-50 rounded-lg p-2 flex items-center justify-center">
                            <TbLogs className="text-indigo-500 w-6 h-6" />
                          </div>
                          <div className="ml-4">
                            <h5 className="text-gray-900 text-base mb-1.5 font-semibold">
                              Blogs
                            </h5>
                            <p className="text-xs font-medium text-gray-400">
                              Read insights and updates on careers, recruitment, and industry trends.
                            </p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          href={`${window.location.origin}/cookie-policy`}
                          className="flex items-start hover:bg-gray-100 p-3 rounded-lg transition"
                        >
                          <div className="bg-indigo-50 rounded-lg p-2 flex items-center justify-center">
                            <FaCookieBite className="text-indigo-500 w-6 h-6" />
                          </div>
                          <div className="ml-4">
                            <h5 className="text-gray-900 text-base mb-1.5 font-semibold">
                              Cookies Policy
                            </h5>
                            <p className="text-xs font-medium text-gray-400">
                              Understand how we use cookies to enhance your experience.
                            </p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          href={`${window.location.origin}/terms-and-conditions`}
                          className="flex items-start hover:bg-gray-100 p-3 rounded-lg transition"
                        >
                          <div className="bg-indigo-50 rounded-lg p-2 flex items-center justify-center">
                            <LuCopyCheck className="text-indigo-500 w-6 h-6" />
                          </div>
                          <div className="ml-4">
                            <h5 className="text-gray-900 text-base mb-1.5 font-semibold">
                              Terms and Conditions
                            </h5>
                            <p className="text-xs font-medium text-gray-400">
                              Review the rules governing the use of our platform.
                            </p>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Optional Actions (Uncomment if needed) */}
                {/* <div className="mt-6 flex flex-col lg:flex-row gap-y-4 lg:items-center justify-center">
                  <button
                    onClick={() => navigate("/login")}
                    className="bg-indigo-50 text-indigo-600 rounded-full font-semibold text-sm py-3 px-6 hover:bg-indigo-100 transition-all"
                  >
                    Login
                  </button>
                  <button
                    onClick={() => navigate("/signup")}
                    className="bg-indigo-600 text-white rounded-full font-semibold text-sm py-3 px-6 lg:ml-5 hover:bg-indigo-700 transition-all"
                  >
                    Sign Up
                  </button>
                </div> */}
              </div>
            )}
          </div>
        </nav>

        {/* Action Buttons (Desktop) */}
        <div className="hidden md:flex space-x-4">
          <a
            href={
              window.location.hostname.includes('job-seekers') || window.location.pathname.includes('job-seekers')
                ? 'https://dashboard.faangai.com/signin'
                : `${window.location.origin}/early-access`
            }
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#251A3D] text-white px-4 py-2 rounded-full text-sm shadow hover:bg-indigo-700"
          >
            Get Started
          </a>

          <button
            onClick={() => navigate("/talk-to-founders")}
            className="border border-gray-300 text-gray-700 px-4 py-2 rounded-full text-sm hover:border-indigo-600 hover:text-indigo-600"
          >
            Book a Demo
          </button>
        </div>

        {/* Mobile Menu Toggle */}
        <button
          className="md:hidden focus:outline-none"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <IconContext.Provider value={{ size: "1.5em" }}>
            {mobileMenuOpen ? <FiX /> : <FiMenu />}
          </IconContext.Provider>
        </button>
      </div>

      {/* Mobile Navigation Menu */}
      {mobileMenuOpen && (
        <nav className="md:hidden bg-white shadow-lg px-5 py-4 rounded-md space-y-4 text-sm font-medium">
          <span
            className="block text-gray-800 hover:text-indigo-500 transition-colors cursor-pointer"
            onClick={() => handleNavigate("/companies")}
          >
            For Recruiters
          </span>
          <span
            className="block text-gray-800 hover:text-indigo-500 transition-colors cursor-pointer"
            onClick={() => handleNavigate("/job-seekers")}
          >
            For Jobseekers
          </span>

          {/* Resources Mega Menu in Mobile */}
          <div className="block">
            <button
              onClick={() => setMobileMegaMenuOpen(!mobileMegaMenuOpen)}
              className="w-full flex items-center justify-between text-gray-800 hover:text-indigo-500 transition-colors cursor-pointer"
            >
              <span>Resources</span>
              <svg
                className={`w-4 h-4 transition-transform ${mobileMegaMenuOpen ? "transform rotate-180" : ""
                  }`}
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
              >
                <path d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {mobileMegaMenuOpen && (
              <div className="mt-2 bg-gray-100 p-4 rounded-md space-y-6">
                {/* For Recruiters Section */}
                <div>
                  <h6 className="font-medium text-sm text-gray-500 mb-2">
                    For Recruiters
                  </h6>
                  <ul className="space-y-2">
                    <li>
                      <a
                        href={`${window.location.origin}/features`}
                        className="flex items-center hover:text-indigo-600 transition-colors"
                        onClick={() => handleNavigate("/features")}
                      >
                        <GrTechnology className="text-indigo-500 w-5 h-5" />
                        <span className="ml-2 text-gray-800">Features</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${window.location.origin}/pricing`}
                        className="flex items-center hover:text-indigo-600 transition-colors"
                        onClick={() => handleNavigate("/pricing")}
                      >
                        <IoPricetag className="text-indigo-500 w-5 h-5" />
                        <span className="ml-2 text-gray-800">Pricing</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${window.location.origin}/partners`}
                        className="flex items-center hover:text-indigo-600 transition-colors"
                        onClick={() => handleNavigate("/partners")}
                      >
                        <FaHandshakeSimple className="text-indigo-500 w-5 h-5" />
                        <span className="ml-2 text-gray-800">Our Partners</span>
                      </a>
                    </li>
                  </ul>
                </div>

                {/* For Jobseekers Section */}
                <div>
                  <h6 className="font-medium text-sm text-gray-500 mb-2">
                    For Jobseekers
                  </h6>
                  <ul className="space-y-2">
                    <li>
                      <a
                        href={`${window.location.origin}/job-seekers/pricing`}
                        className="flex items-center hover:text-indigo-600 transition-colors"
                        onClick={() => handleNavigate("/job-seekers/pricing")}
                      >
                        <IoPricetag className="text-indigo-500 w-5 h-5" />
                        <span className="ml-2 text-gray-800">Pricing</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${window.location.origin}/job-seekers/partners`}
                        className="flex items-center hover:text-indigo-600 transition-colors"
                        onClick={() => handleNavigate("/job-seekers/partners")}
                      >
                        <HiOutlineUserGroup className="text-indigo-500 w-5 h-5" />
                        <span className="ml-2 text-gray-800">Community Partners</span>
                      </a>
                    </li>
                  </ul>
                </div>

                {/* General Section */}
                <div>
                  <h6 className="font-medium text-sm text-gray-500 mb-2">
                    General
                  </h6>
                  <ul className="space-y-2">
                    <li>
                      <a
                        href={`${window.location.origin}/aboutus`}
                        className="flex items-center hover:text-indigo-600 transition-colors"
                        onClick={() => handleNavigate("/aboutus")}
                      >
                        <RiArticleLine className="text-indigo-500 w-5 h-5" />
                        <span className="ml-2 text-gray-800">About Us</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${window.location.origin}/blogs`}
                        className="flex items-center hover:text-indigo-600 transition-colors"
                        onClick={() => handleNavigate("/blogs")}
                      >
                        <TbLogs className="text-indigo-500 w-5 h-5" />
                        <span className="ml-2 text-gray-800">Blogs</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${window.location.origin}/cookie-policy`}
                        className="flex items-center hover:text-indigo-600 transition-colors"
                        onClick={() => handleNavigate("/cookie-policy")}
                      >
                        <FaCookieBite className="text-indigo-500 w-5 h-5" />
                        <span className="ml-2 text-gray-800">Cookies Policy</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${window.location.origin}/terms-and-conditions`}
                        className="flex items-center hover:text-indigo-600 transition-colors"
                        onClick={() => handleNavigate("/terms-and-conditions")}
                      >
                        <LuCopyCheck className="text-indigo-500 w-5 h-5" />
                        <span className="ml-2 text-gray-800">Terms and Conditions</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>

          {/* Action Buttons */}
          <div className="space-y-3">
            <a
              href={
                window.location.hostname.includes('job-seekers') || window.location.pathname.includes('job-seekers')
                  ? 'https://dashboard.faangai.com/signin'
                  : `${window.location.origin}/early-access`
              }
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#251A3D] text-white px-4 py-2 rounded-full text-sm shadow hover:bg-indigo-700 block text-center"
            >
              Get Started
            </a>

            <button
              onClick={() => handleNavigate("/talk-to-founders")}
              className="w-full border border-gray-300 text-gray-700 px-4 py-2 rounded-full text-sm hover:border-indigo-600 hover:text-indigo-600"
            >
              Book a Demo
            </button>
          </div>
        </nav>
      )}
    </header>
  );
};

export default Navbar;
