import React, { useState, useEffect, useRef } from "react";
import { MdEmail, MdOutlineMarkEmailRead } from "react-icons/md";

const SubscribeModal = ({ isOpen, onClose, onSubscribe }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const modalRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (!email) {
      setError("Please enter a valid email.");
      return;
    }

    try {
      await onSubscribe(email);
      setSuccess("Subscription successful!");
      setEmail("");
      localStorage.setItem("hasSubscribed", true);
      localStorage.setItem("subscriberEmail", email);
    } catch (err) {
      setError(err.message || "Subscription failed.");
    }
  };

  // Close modal when clicking outside of it
  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60">
      <div
        ref={modalRef}
        className="bg-gradient-to-r from-gray-50 via-white to-indigo-50 rounded-2xl shadow-2xl w-11/12 max-w-lg p-10 text-white"
      >
         <div className="flex items-center justify-center mb-6">
          <div className="w-14 h-14 bg-indigo-200 rounded-full flex items-center justify-center">
            <MdOutlineMarkEmailRead className="text-indigo-500 text-4xl" />
          </div>
        </div>
        <h2 className="text-3xl font-semibold text-gray-800 text-center mb-4">
          Stay Ahead with Asendia
        </h2>
        <p className="text-center text-gray-600 text-md mb-6">
          Join our newsletter to be the first to know about new features,
          updates, and exclusive tips to maximize your Asendia experience.
        </p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="email"
            placeholder="Enter your email address"
            className="w-full p-3 border rounded-md text-gray-700 border-gray-300 focus:ring-2 focus:ring-indigo-500 outline-none"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {error && <p className="text-red-500 text-sm">{error}</p>}
          {success && <p className="text-green-500 text-sm">{success}</p>}
          <button
            type="submit"
            className="w-full py-3 bg-indigo-400 text-black font-bold rounded-full hover:bg-indigo-500 transition duration-200"
          >
            <span role="img" aria-label="rocket">🚀</span> Subscribe
          </button>

          {/* <button
              type="button"
              className="w-full py-3 bg-gray-300 text-black font-bold rounded-full hover:bg-indigo-400 transition duration-200"
              onClick={onClose}
            >
              Close
            </button> */}
        </form>
        <p className="text-gray-500 text-sm mt-4 text-center">
          By subscribing, you agree to our{" "}
          <a href="#" className="underline">
            Privacy Policy
          </a>{" "}
          and{" "}
          <a href="#" className="underline">
            Terms of Service
          </a>.
        </p>
      </div>
    </div>
  );
};

export default SubscribeModal;
