// src/pages/OurPartners/OurPartners.jsx

import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {
    FaRegHandshake,
    FaRegLightbulb,
    FaRegBuilding,
} from "react-icons/fa";
import { PiStrategyBold } from "react-icons/pi";
import {
    FiSettings,
    FiShield,
    FiZap,
    FiStar,
    FiCloud,
    FiTrendingUp,
    FiUsers,
    FiDollarSign,
    FiLayers,
} from "react-icons/fi";
import { GrTechnology } from "react-icons/gr";
import { TbWriting } from "react-icons/tb";
import avatar1 from "../../assets/Avatar4.jpg";
import avatar2 from "../../assets/Avatar6.png";
import avatar3 from "../../assets/Avatar8.png";
import NewsletterSection from "../../Components/NewsLetter/NewsLetter";
import BusinessIntroducers from "../../assets/BusinessIntroducers.png";
import TechnologyPartners from "../../assets/TechnologyPartners.png";
import ContentCreators from "../../assets/ContentCreators.png";

// Animation Variants
const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: (custom = 1) => ({
        opacity: 1,
        y: 0,
        transition: {
            delay: custom * 0.2,
            duration: 0.6,
            ease: "easeOut",
        },
    }),
};

const gridContainerVariants = {
    hidden: {},
    visible: {
        transition: {
            staggerChildren: 0.2,
        },
    },
};

const cardVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: { duration: 0.5, ease: "easeOut" },
    },
};

const buttonVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (custom = 1) => ({
        opacity: 1,
        y: 0,
        transition: {
            delay: custom * 0.2,
            duration: 0.5,
            ease: "easeOut",
        },
    }),
};

const OurPartners = () => {
    const navigate = useNavigate();

    // Navigate to the specified route
    const handleNavigate = () => {
        navigate("/partners/partner-contact");
    };

    const features = [
        {
            title: "Business Development",
            description:
                "Expand your customer portfolio and diversify revenue streams with predictive recruitment and talent management solutions.",
            icon: <FiTrendingUp className="w-12 h-12 text-indigo-600" />,
        },
        {
            title: "Brand Image",
            description:
                "Enhance your market presence with industry-leading talent assessment and engaging content for your social networks.",
            icon: <FiUsers className="w-12 h-12 text-indigo-600" />,
        },
        {
            title: "Loyalty",
            description:
                "Broaden your service offerings, increase customer impact, and foster long-term client loyalty.",
            icon: <FiStar className="w-12 h-12 text-indigo-600" />,
        },
        {
            title: "Innovation",
            description:
                "Stay ahead with cutting-edge innovations in talent assessment and recruitment for a competitive edge.",
            icon: <FiLayers className="w-12 h-12 text-indigo-600" />,
        },
    ];

    const contactMethods = [
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                </svg>
            ),
            contact: "Support@example.com",
        },
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                    />
                </svg>
            ),
            contact: "+1 (555) 000-000",
        },
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                    />
                </svg>
            ),
            contact: "Mountain View, California, United States.",
        },
    ];

    const cards = [
        {
            title: "Technology Partners",
            description:
                "Integrate Asendia AI into your solution to bring more value to your customers.",
            button: "Learn More",
            icon: TechnologyPartners,
        },
        {
            title: "Business Introducers",
            description:
                "Introduce Asendia AI to your customers and earn a commission on every product they purchase.",
            button: "Learn More",
            icon: BusinessIntroducers,
        },
        {
            title: "Content Creators",
            description: "Join our program for content creators and generate revenue.",
            button: "Learn More",
            icon: ContentCreators,
        },
    ];

    return (
        <div className="bg-white">
            {/* Hero Section */}
            <motion.section
                className="py-32"
                initial="hidden"
                animate="visible"
                variants={{
                    hidden: { opacity: 0 },
                    visible: {
                        opacity: 1,
                        transition: { duration: 1 },
                    },
                }}
            >
                <div className="max-w-screen-xl mx-auto text-gray-600 gap-x-12 items-center justify-between overflow-hidden md:flex md:px-8">
                    <motion.div
                        className="flex-none space-y-5 px-4 sm:max-w-lg md:px-0 lg:max-w-xl"
                        variants={containerVariants}
                        custom={0}
                    >
                        <motion.h2
                            className="text-sm text-indigo-600 font-medium"
                            variants={containerVariants}
                        >
                            Over 50+ successful deals
                        </motion.h2>
                        <motion.h1
                            className="text-4xl text-gray-800 font-bold md:text-5xl"
                            variants={containerVariants}
                        >
                            Let’s Reinvent{" "}
                            <span className="bg-clip-text bg-gradient-to-r from-indigo-500 via-indigo-800 to-indigo-400 text-transparent">
                                Partnership{" "}
                            </span>
                            Together
                        </motion.h1>
                        <motion.p variants={containerVariants}>
                            Revolutionize talent acquisition with cutting-edge AI solutions and
                            join the future of recruitment.
                        </motion.p>
                        <motion.div
                            className="items-center gap-x-3 space-y-3 sm:flex sm:space-y-0"
                            variants={containerVariants}
                            custom={1}
                        >
                            <motion.a
                                onClick={handleNavigate}
                                className="cursor-pointer block py-3 px-5 text-center text-white font-medium bg-indigo-600 duration-150 hover:bg-indigo-500 active:bg-indigo-700 rounded-full shadow-lg hover:shadow-none"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                variants={buttonVariants}
                                custom={2}
                            >
                                Become Partner
                            </motion.a>
                            <motion.a
                                href="https://chat.whatsapp.com/Io18gNUCKqcDWQtjwV6t2R"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center justify-center gap-x-2  py-3 px-5 text-gray-700 hover:text-gray-500 font-medium duration-150 active:bg-gray-100 border rounded-full md:inline-flex"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                variants={buttonVariants}
                                custom={3}
                            >

                                Join our Community
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="w-5 h-5"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </motion.a>
                        </motion.div>
                    </motion.div>
                    <motion.div
                        className="relative flex-none mt-14 md:mt-0 md:max-w-xl"
                        variants={containerVariants}
                        custom={4}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                    >
                        {/* Image */}
                        <motion.img
                            src="https://img.freepik.com/free-photo/business-executives-using-laptop_1170-1755.jpg?t=st=1736714300~exp=1736717900~hmac=6ab075b70f5feff40459faf5d1828cff4faaf871c87a2f683654a32525e76888&w=1380"
                            className="md:rounded-tl-[108px] relative z-10"
                            alt="Hero Banner"
                            initial={{ opacity: 0, scale: 0.95 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 1, ease: "easeOut" }}
                        />
                        {/* Decorative Lines */}
                        <motion.div
                            className="absolute inset-0 pointer-events-none"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            variants={{
                                hidden: {},
                                visible: {
                                    transition: {
                                        staggerChildren: 0.3,
                                    },
                                },
                            }}
                        >
                            {/* Top Left Line */}
                            <motion.div
                                style={{
                                    borderTopLeftRadius: "20px",
                                }}
                                className="absolute top-[-10px] left-[-15px] w-20 h-20 border-t-4 mt-8 border-l-4 border-indigo-500"
                                variants={{
                                    hidden: { opacity: 0, x: -50, y: -50 },
                                    visible: {
                                        opacity: 1,
                                        x: 0,
                                        y: 0,
                                        transition: { duration: 1, ease: "easeOut" },
                                    },
                                }}
                            />
                            {/* Bottom Right Line */}
                            <motion.div
                                className="absolute bottom-[-20px] right-[-20px] w-20 h-20 border-b-4 border-r-4 border-indigo-500"
                                variants={{
                                    hidden: { opacity: 0, x: 50, y: 50 },
                                    visible: {
                                        opacity: 1,
                                        x: 0,
                                        y: 0,
                                        transition: { duration: 1, ease: "easeOut" },
                                    },
                                }}
                            />
                        </motion.div>
                    </motion.div>
                </div>
            </motion.section>

            {/* How to Become a Partner Section */}
            <motion.section
                className="bg-gray-50 py-12"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={{
                    hidden: { opacity: 0 },
                    visible: { opacity: 1, transition: { duration: 1 } },
                }}
            >
                <div className="max-w-7xl mx-auto px-6 lg:px-8">
                    {/* Header */}
                    <motion.div
                        className="text-center mb-12"
                        variants={containerVariants}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.2 }}
                    >
                        <motion.h2
                            className="text-3xl sm:text-4xl font-bold text-gray-900 mt-12 mb-4 text-center"
                            variants={containerVariants}
                            custom={0}
                        >
                            How Can I Become an{" "}
                            <span className="bg-clip-text bg-gradient-to-r from-indigo-500 via-indigo-800 to-indigo-400 text-transparent">
                                Asendia AI
                            </span>{" "}
                            Partner
                        </motion.h2>
                        <motion.p
                            className="mt-4 text-base text-gray-600 max-w-2xl mx-auto"
                            variants={containerVariants}
                            custom={1}
                        >
                            Discover a variety of partnership opportunities tailored to your
                            business needs.
                        </motion.p>
                    </motion.div>

                    {/* First Section */}
                    <motion.div
                        className="flex flex-col lg:flex-row items-center gap-12 mb-16 !mt-[130px]"
                        variants={gridContainerVariants}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.2 }}
                    >
                        {/* Text Section */}
                        <motion.div
                            className="lg:w-1/2 text-center lg:text-left space-y-6"
                            variants={cardVariants}
                        >
                            <h3 className="text-3xl font-semibold text-gray-900">
                                Explore Partnership Opportunities
                            </h3>
                            <p className="text-base text-gray-600">
                                We provide tailored partnership opportunities that suit your
                                business model.
                            </p>
                            <button
                                onClick={handleNavigate}
                                className="px-6 py-3 bg-indigo-500 text-white rounded-full shadow hover:bg-indigo-600 transition"
                            >
                                Become a Partner
                            </button>
                        </motion.div>

                        {/* Image Section with Decorative Box */}
                        <motion.div
                            className="lg:w-1/2 relative"
                            variants={cardVariants}
                        >
                            {/* Indigo Decorative Box */}
                            <motion.div
                                className="absolute -top-8 -right-8 w-32 h-32 lg:w-40 lg:h-40 bg-indigo-500 rounded-lg z-0"
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 1, ease: "easeOut" }}
                            ></motion.div>
                            {/* Image */}
                            <motion.img
                                src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?q=80&w=2070&auto=format&fit=crop"
                                alt="Partner illustration"
                                className="w-full rounded-2xl shadow-lg object-cover h-80 lg:h-full relative z-10"
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 1, ease: "easeOut" }}
                            />
                        </motion.div>
                    </motion.div>

                    {/* Second Section */}
                    <motion.div
                        className="flex flex-col lg:flex-row items-center gap-12 mb-16"
                        variants={gridContainerVariants}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.2 }}
                    >
                        <motion.div
                            className="lg:w-1/2"
                            variants={cardVariants}
                        >
                           <img
    src="https://images.unsplash.com/photo-1523240795612-9a054b0db644?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    alt="Reseller distributors"
    className="w-full rounded-2xl shadow-lg object-cover h-80 lg:h-full"
/>

                        </motion.div>
                        <motion.div
                            className="lg:w-1/2 text-center lg:text-left space-y-6"
                            variants={cardVariants}
                        >
                            <h3 className="text-3xl font-semibold text-gray-900">
                                Reseller Distributors
                            </h3>
                            <p className="text-base text-gray-600">
                                Develop your business by becoming a distributor or reseller of
                                Asendia AI solutions. Eligibility conditions apply.
                            </p>
                            <button
                                onClick={handleNavigate}
                                className="px-6 py-3 bg-indigo-500 text-white rounded-full shadow hover:bg-indigo-600 transition"
                            >
                                Become a Reseller
                            </button>
                        </motion.div>
                    </motion.div>

                    {/* Partnership Cards */}
                    <motion.div
                        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16"
                        variants={gridContainerVariants}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.2 }}
                    >
                        {cards.map(({ title, description, button, icon }, index) => (
                            <motion.div
                                key={index}
                                className="bg-white p-8 rounded-3xl shadow-md hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center"
                                variants={cardVariants}
                            >
                                <img className="h-[100px] mb-10" src={icon} alt={`${title} icon`} />
                                <h4 className="text-xl font-semibold text-gray-800 mb-2 mt-2">
                                    {title}
                                </h4>
                                <p className="text-gray-600 mb-4">{description}</p>
                                {/* <button
                                    onClick={handleNavigate}
                                    className="mt-auto px-5 py-2 bg-white text-indigo-700 rounded-full hover:bg-indigo-400 hover:text-white transition flex items-center gap-2"
                                >
                                    {button}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M9 5l7 7-7 7"
                                        />
                                    </svg>
                                </button> */}
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </motion.section>

            {/* Contact Us Section */}
            <motion.section
                className="py-24"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={{
                    hidden: { opacity: 0 },
                    visible: { opacity: 1, transition: { duration: 1 } },
                }}
            >
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <motion.div
                        className="mb-10 lg:mb-16 flex justify-center items-center flex-col gap-x-0 gap-y-6 lg:gap-y-0 lg:flex-row lg:justify-between max-md:max-w-lg max-md:mx-auto"
                        variants={containerVariants}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.2 }}
                    >
                        <motion.div
                            className="relative w-full text-center lg:text-left lg:w-2/3"
                            variants={containerVariants}
                            custom={0}
                        >
                            <h2 className="text-block text-3xl font-bold text-gray-900 leading-[2rem] lg:mb-6 mx-auto max-w-max lg:max-w-[35rem] lg:mx-0">
                                Becoming an{" "}
                                <span className="bg-clip-text bg-gradient-to-r from-indigo-500 via-indigo-800 to-indigo-400 text-transparent">
                                    Asendia AI
                                </span>{" "}
                                partner means joining a team that invests in your success.
                            </h2>
                        </motion.div>
                        <motion.div
                            className="relative w-full text-center lg:text-left lg:w-2/4"
                            variants={containerVariants}
                            custom={1}
                        >
                            <p className="text-lg font-normal text-gray-500 mb-5">
                                We provide you with all the tools you need to develop your
                                potential assessment business.
                            </p>
                            <a
                                onClick={handleNavigate}
                                className="flex flex-row items-center justify-center gap-2 text-base font-semibold text-indigo-600 lg:justify-start hover:text-indigo-700 cursor-pointer"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                Get Started
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.5 15L11.0858 11.4142C11.7525 10.7475 12.0858 10.4142 12.0858 10C12.0858 9.58579 11.7525 9.25245 11.0858 8.58579L7.5 5"
                                        stroke="#4F46E5"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                </svg>
                            </a>
                        </motion.div>
                    </motion.div>
                    <motion.div
                        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-md:max-w-md mx-auto"
                        variants={gridContainerVariants}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.2 }}
                    >
                        {features.map((feature, index) => (
                            <motion.div
                                key={index}
                                className="border bg-white rounded-2xl overflow-hidden shadow-md hover:shadow-lg transition-all"
                                variants={cardVariants}
                            >
                                <div className="p-8 text-left flex flex-col gap-6">
                                    {feature.icon}
                                    <div>
                                        <h3 className="text-gray-800 text-xl font-semibold mb-3">
                                            {feature.title}
                                        </h3>
                                        <p className="text-gray-500 text-sm leading-relaxed">
                                            {feature.description}
                                        </p>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </motion.section>

            {/* Contact Info and Form */}
            {/* <div className="bg-gray-50">
        <motion.div
          className="max-w-screen-xl mx-auto px-4 py-16 text-gray-600 md:px-8"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1, transition: { duration: 1 } },
          }}
        >
          <div className="max-w-5xl mx-auto gap-12 justify-between lg:flex lg:max-w-none">
        
            <motion.div
              className="max-w-lg space-y-5"
              variants={containerVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
            >
              <motion.h3
                className="text-indigo-600 text-lg font-semibold tracking-wide"
                variants={containerVariants}
                custom={0}
              >
                Get in Touch
              </motion.h3>
              <motion.p
                className="text-gray-900 text-3xl font-bold leading-tight sm:text-4xl"
                variants={containerVariants}
                custom={1}
              >
                We’re here to help
              </motion.p>
              <motion.p
                className="text-gray-700 leading-relaxed"
                variants={containerVariants}
                custom={2}
              >
                Have questions? We’d love to hear from you! Fill out the form or
                use the contact details below to get in touch.
              </motion.p>
              <motion.ul
                className="mt-8 flex flex-col gap-6"
                variants={gridContainerVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
              >
                {contactMethods.map((item, idx) => (
                  <motion.li
                    key={idx}
                    className="border flex items-center gap-x-4 bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition"
                    variants={cardVariants}
                  >
                    <div className="flex-none text-indigo-600 text-2xl">
                      {item.icon}
                    </div>
                    <p className="text-gray-800 font-medium">
                      {item.contact}
                    </p>
                  </motion.li>
                ))}
              </motion.ul>
            </motion.div>

            <motion.div
              className="flex-1 mt-12 lg:mt-0 lg:max-w-lg"
              variants={cardVariants}
            >
              <form
                onSubmit={(e) => e.preventDefault()}
                className="space-y-6 bg-white p-10 rounded-2xl shadow-lg border border-gray-100"
              >
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                  viewport={{ once: true, amount: 0.2 }}
                >
                  <label
                    htmlFor="fullName"
                    className="block text-gray-700 font-medium"
                  >
                    Full Name
                  </label>
                  <input
                    id="fullName"
                    type="text"
                    required
                    className="w-full mt-2 px-4 py-3 text-gray-700 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, ease: "easeOut", delay: 0.1 }}
                  viewport={{ once: true, amount: 0.2 }}
                >
                  <label
                    htmlFor="email"
                    className="block text-gray-700 font-medium"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    required
                    className="w-full mt-2 px-4 py-3 text-gray-700 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, ease: "easeOut", delay: 0.2 }}
                  viewport={{ once: true, amount: 0.2 }}
                >
                  <label
                    htmlFor="company"
                    className="block text-gray-700 font-medium"
                  >
                    Company
                  </label>
                  <input
                    id="company"
                    type="text"
                    required
                    className="w-full mt-2 px-4 py-3 text-gray-700 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, ease: "easeOut", delay: 0.3 }}
                  viewport={{ once: true, amount: 0.2 }}
                >
                  <label
                    htmlFor="message"
                    className="block text-gray-700 font-medium"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    required
                    className="w-full mt-2 px-4 py-3 text-gray-700 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 h-36 resize-none"
                  ></textarea>
                </motion.div>
                <motion.button
                  type="submit"
                  className="w-full px-6 py-3 text-white font-semibold bg-indigo-600 hover:bg-indigo-500 rounded-full shadow-md transition duration-150"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Submit
                </motion.button>
              </form>
            </motion.div>
          </div>
        </motion.div>
      </div> */}

            {/* Divider Lines */}
            <div className="my-10 w-[80%] mx-auto h-[1px] bg-gray-200/0"></div>
            <div className="my-10 w-[80%] mx-auto h-[1px] bg-gray-200/0"></div>

            {/* Newsletter Section */}
            <div className="max-w-7xl mx-auto">
                <NewsletterSection />
            </div>
        </div>
    );
};

export default OurPartners;
